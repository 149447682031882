import ModelTekno from "./teknoModel";



const digital=[

    new ModelTekno({
        text:"Photoshop"
    }),
    new ModelTekno({
        text:"Figma"
    }),
    new ModelTekno({
        text:"Suite Office"
    })
]

export default digital