import ModelTekno from "./teknoModel";


const tek=[

    new ModelTekno({


        text:"Tailwind"


    }),
    new ModelTekno({


        text:"Javascript"


    }),
    new ModelTekno({


        text:"React"


    }),
    new ModelTekno({


        text:"Wordpress"


    }),
    new ModelTekno({


        text:"Git"


    }),
    new ModelTekno({


        text:"Bootstrap"


    }),
    new ModelTekno({


        text:"StoryBook"


    }),
]


export default tek